<template>
    <div class="page-container">
        <div class="page-head">
            <div class="page-title">藏品</div>
            <!-- <div class="sort">
                <div class="sort-box">
                    <span>按照时间顺序</span>
                    <img src="../../assets/market-sort-arrow.png" alt="" class="sort-arrow">
                </div>
                <div class="sort-list">
                    <div class="sort-item">按照时间顺序</div>
                    <div class="sort-item">价格从低到高</div>
                    <div class="sort-item">价格从高到低</div>
                </div>
            </div> -->
        </div>
        <div class="product-list">
            <div class="product-item" @click="jumpPage('productdetail1')">
                <div class="pic-box">
                    <div class="pic-wrapper">
                        <div class="fav-box">
                            <img src="../../assets/productdetail/icon-fav.png" alt="" class="icon-fav"> 
                            <span>30</span>
                        </div>
                        <img src="../../assets/main/tmp-yu.png" alt="" class="product-pic">
                    </div>
                    <div class="product-name">「Esprimere 表达」欢腾鱼-青：自由之约</div>
                    <div class="sale-status">
                        <img src="../../assets/main/icon-hot.png" alt="" class="status-icon"> 
                        <span> 热销中</span>
                    </div>
                </div>
                <div class="auth-info">
                    <span>艺术家</span>
                    <span>墨菲链</span>
                </div>
                <div class="price-line">
                    <span>价格</span>
                    <span>¥19.9</span>
                </div>
            </div>
            <div class="product-item" @click="jumpPage('productdetail2')">
                <div class="pic-box">
                    <div class="pic-wrapper">
                        <div class="fav-box">
                            <img src="../../assets/productdetail/icon-fav.png" alt="" class="icon-fav"> 
                            <span>30</span>
                        </div>
                        <img src="../../assets/main/tmp-fo.png" alt="" class="product-pic">
                    </div>
                    <div class="product-name">《灯光佛影》</div>
                    <div class="sale-status">
                        <img src="../../assets/main/icon-hot.png" alt="" class="status-icon"> 
                        <span> 热销中</span>
                    </div>
                </div>
                <div class="auth-info">
                    <span>艺术家</span>
                    <span>王文喜</span>
                </div>
                <div class="price-line">
                    <span>价格</span>
                    <span>¥49.9</span>
                </div>
            </div>
            <div class="product-item" @click="jumpPage('productdetail3')">
                <div class="pic-box">
                    <div class="pic-wrapper">
                        <div class="fav-box">
                            <img src="../../assets/productdetail/icon-fav.png" alt="" class="icon-fav"> 
                            <span>30</span>
                        </div>
                        <img src="../../assets/main/tmp-book1.png" alt="" class="product-pic">
                    </div>
                    <div class="product-name">《中国元宇宙经济白皮书》</div>
                    <div class="sale-status">
                        <img src="../../assets/main/icon-hot.png" alt="" class="status-icon"> 
                        <span> 热销中</span>
                    </div>
                </div>
                <div class="auth-info">
                    <span>艺术家</span>
                    <span>北京信息产业协会元宇宙专委会（筹）</span>
                </div>
                <div class="price-line">
                    <span>价格</span>
                    <span>¥200</span>
                </div>
            </div>
            <div class="product-item" @click="jumpPage('productdetail3')">
                <div class="pic-box">
                    <div class="pic-wrapper">
                        <div class="fav-box">
                            <img src="../../assets/productdetail/icon-fav.png" alt="" class="icon-fav"> 
                            <span>30</span>
                        </div>
                        <img src="../../assets/main/tmp-book2.png" alt="" class="product-pic">
                    </div>
                    <div class="product-name">「Esprimere 表达」欢腾鱼-青：自由之约</div>
                    <div class="sale-status">
                        <img src="../../assets/main/icon-hot.png" alt="" class="status-icon"> 
                        <span> 热销中</span>
                    </div>
                </div>
                <div class="auth-info">
                    <span>艺术家</span>
                    <span>北京信息产业协会元宇宙专委会（筹）</span>
                </div>
                <div class="price-line">
                    <span>价格</span>
                    <span>¥200</span>
                </div>
            </div>
            <div class="product-item" @click="jumpPage('productdetail4')">
                <div class="pic-box">
                    <div class="pic-wrapper">
                        <div class="fav-box">
                            <img src="../../assets/productdetail/icon-fav.png" alt="" class="icon-fav"> 
                            <span>30</span>
                        </div>
                        <img src="../../assets/main/tmp-hz.png" alt="" class="product-pic">
                    </div>
                    <div class="product-name">发布会纪念版徽章</div>
                    <div class="sale-status">
                        <img src="../../assets/main/icon-hot.png" alt="" class="status-icon"> 
                        <span> 热销中</span>
                    </div>
                </div>
                <div class="auth-info">
                    <span>艺术家</span>
                    <span>北京信息产业协会元宇宙专委会（筹）</span>
                </div>
                <div class="price-line">
                    <span>价格</span>
                    <span>¥200</span>
                </div>
            </div>
            <div class="product-item" @click="jumpPage('productdetail5')">
                <div class="pic-box">
                    <div class="pic-wrapper">
                        <div class="fav-box">
                            <img src="../../assets/productdetail/icon-fav.png" alt="" class="icon-fav"> 
                            <span>30</span>
                        </div>
                        <img src="../../assets/main/tmp-djq.png" alt="" class="product-pic">
                    </div>
                    <div class="product-name">「Esprimere 表达」欢腾鱼-青：自由之约 拷贝</div>
                    <div class="sale-status">
                        <img src="../../assets/main/icon-hot.png" alt="" class="status-icon"> 
                        <span> 热销中</span>
                    </div>
                </div>
                <div class="auth-info">
                    <span>艺术家</span>
                    <span>《中国元宇宙社会治理白皮书》收藏券</span>
                </div>
                <div class="price-line">
                    <span>价格</span>
                    <span>¥200</span>
                </div>
            </div>
            <div class="product-item hidden"></div>
            <div class="product-item hidden"></div>
        </div>
    </div>
</template>

<script>
export default {
    methods:{
        jumpPage(name){
            this.$router.push({
                name
            });
        }
    }
}
</script>

<style lang="scss" src="./index.scss" scoped>

</style>